import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBmvoivWQqAvLldQ4jICJTr0DMXnGN1E3c",
  authDomain: "xlnc-330e1.firebaseapp.com",
  databaseURL: "https://xlnc-330e1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "xlnc-330e1",
  storageBucket: "xlnc-330e1.appspot.com",
  messagingSenderId: "1090310559813",
  appId: "1:1090310559813:web:ebb3e7d582be4e44812b2c",
  measurementId: "G-6BE1W5QBJL"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { firestore, auth, googleProvider, signOut, firebaseConfig, collection, addDoc, doc, deleteDoc };
