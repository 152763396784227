import { firestore } from './firebase.config.js';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';

// Job Data and Constants
let jobData = [];
const jobsPerPage = 15; // Number of jobs to display per page
let currentPage = 1; // Current page number
let totalPages = 0; // Total number of pages
let activeFilters = []; // Array to store active job type filters
let includeXLancFilter = false; // Boolean to track XLanc filter
let isLoading = false; // Loading state

// General function to fetch jobs for a specific page
async function fetchAllJobListings(pageNumber = 1) {
    try {
        isLoading = true;
        showLoadingIndicator();

        const startAt = (pageNumber - 1) * jobsPerPage;
        const collections = ['jobs', 'freelancer_jobs', 'company_jobs'];
        const allJobsPromises = collections.map(collectionName => fetchJobListingsFromCollection(collectionName, startAt, jobsPerPage));
        const allJobs = (await Promise.all(allJobsPromises)).flat();

        jobData = allJobs;
        totalPages = Math.ceil(jobData.length / jobsPerPage); // Calculate total pages based on jobs fetched

        console.log("Total Jobs:", jobData.length); // Debugging logs
        console.log("Total Pages:", totalPages); // Debugging logs

        if (totalPages > 1) {
            goToPage(pageNumber); // Display the jobs for the selected page
            generatePagination(); // Generate the pagination buttons
        } else {
            populateJobListings(jobData); // If only one page, display all jobs
        }

        hideLoadingIndicator();
        scrollToRecentListings();
    } catch (error) {
        console.error("Error fetching job listings:", error);
        hideLoadingIndicator();
    } finally {
        isLoading = false;
    }
}

// Function to fetch job listings from a specific collection
async function fetchJobListingsFromCollection(collectionName, startAt = 0) {
    try {
        // Removed the limit to fetch all jobs for testing purposes
        let collectionQuery = query(
            collection(firestore, collectionName),
            orderBy('date_posted', 'desc')
        );

        const querySnapshot = await getDocs(collectionQuery);

        const collectionData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            collection: collectionName, // Track the collection source
            ...doc.data()
        }));

        return collectionData.filter(job => applyJobFilter(job, activeFilters, includeXLancFilter));
    } catch (error) {
        console.error(`Error fetching job listings from ${collectionName}:`, error);
        return [];
    }
}

// Display jobs for the current page
function goToPage(pageNumber) {
    currentPage = pageNumber;
    const startIndex = (currentPage - 1) * jobsPerPage;
    const paginatedJobs = jobData.slice(startIndex, startIndex + jobsPerPage);

    populateJobListings(paginatedJobs);
    generatePagination(); // Regenerate pagination buttons to reflect the new page range
}

// Populate job listings for the current page
function populateJobListings(jobs) {
    const jobListingsContainer = document.getElementById("job-listings");
    if (!jobListingsContainer) return;

    jobListingsContainer.innerHTML = ""; // Clear existing listings

    const fragment = document.createDocumentFragment(); // Use document fragment for better performance
    jobs.forEach(job => {
        const jobListingElement = document.createElement("div");
        jobListingElement.classList.add("job-listing");

        const logoUrl = job.logo_photo_url || 'assets/images/placeholder.png';
        const companyIcon = 'assets/images/company-icon.png';
        const datePostedIcon = 'assets/images/date-posted-icon.png';
        const locationIcon = 'assets/images/location-icon.png';

        jobListingElement.innerHTML = `
            <img src="${logoUrl}" alt="${job.company} Logo" loading="lazy">
            <div class="details">
                <h2>${job.title || 'Title not available'}</h2>
                <p>
                    <img src="${companyIcon}" alt="Company Icon" style="width: 24px; height: 24px; margin-right: 8px;"> 
                    <a href="${job.company_url || '#'}" target="_blank" style="color: #3DB8A6; font-weight: bold; text-decoration: none;">${job.company || 'N/A'}</a>
                </p>
                <p>
                    <img src="${datePostedIcon}" alt="Date Posted Icon" style="width: 24px; height: 24px; margin-right: 8px;">
                    ${job.date_posted || 'N/A'}
                </p>
                <p>
                    <img src="${locationIcon}" alt="Location Icon" style="width: 24px; height: 24px; margin-right: 8px;">
                    ${job.location || 'Location not available'}
                </p>
            </div>
        `;

        // Attach the job click event
        jobListingElement.addEventListener('click', () => {
            window.location.href = `job-details.html?jobId=${job.id}&collection=${job.collection}`;
        });

        fragment.appendChild(jobListingElement);
    });

    jobListingsContainer.appendChild(fragment); // Append all elements at once
}

// Generate dynamic pagination buttons based on current page and total pages
function generatePagination() {
    const paginationContainer = document.getElementById('pagination-container');
    paginationContainer.innerHTML = ''; // Clear previous pagination

    const maxPagesToShow = 5; // Maximum number of pages to display at once
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Adjust the start and end page if near the first or last pages
    if (endPage - startPage < maxPagesToShow - 1) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    // "First" button
    if (currentPage > 1) {
        const firstPageButton = createPageButton(1, 'First');
        paginationContainer.appendChild(firstPageButton);
    }

    // "Previous" button
    if (currentPage > 1) {
        const prevPageButton = createPageButton(currentPage - 1, 'Previous');
        paginationContainer.appendChild(prevPageButton);
    }

    // Page buttons
    for (let i = startPage; i <= endPage; i++) {
        const pageButton = createPageButton(i);
        paginationContainer.appendChild(pageButton);
    }

    // "Next" button
    if (currentPage < totalPages) {
        const nextPageButton = createPageButton(currentPage + 1, 'Next');
        paginationContainer.appendChild(nextPageButton);
    }

    // "Last" button
    if (currentPage < totalPages) {
        const lastPageButton = createPageButton(totalPages, 'Last');
        paginationContainer.appendChild(lastPageButton);
    }
}

// Create page button element
function createPageButton(pageNumber, text = null) {
    const pageButton = document.createElement('button');
    pageButton.classList.add('page-button');
    pageButton.textContent = text || pageNumber;
    pageButton.addEventListener('click', () => goToPage(pageNumber));

    if (pageNumber === currentPage) {
        pageButton.classList.add('active');
    }

    console.log("Created page button for page", pageNumber); // Debugging logs

    return pageButton;
}

// Function to apply job filters
function applyJobFilter(job, filters, includeXLanc) {
    let matches = true;

    if (filters.length > 0) {
        matches = filters.some(type => job.title?.toLowerCase().includes(type.toLowerCase()));
    }

    if (includeXLanc) {
        matches = matches && job.tag === 'xlanc';
    }

    return matches;
}

// Function to apply filters and sorting on the job data
function applyFilters(jobTypes = [], includeXLanc = false) {
    activeFilters = jobTypes;
    includeXLancFilter = includeXLanc;

    fetchAllJobListings(1); // Fetch filtered jobs and reset to page 1
}
// Clear Filters Functionality
document.getElementById('clear-filters-button').addEventListener('click', () => {
    // Uncheck all checkboxes
    const checkboxes = document.querySelectorAll('.job-type-filter');
    checkboxes.forEach(checkbox => {
        checkbox.checked = false;
    });

    // Uncheck the XLANC filter if checked
    document.getElementById('include-xlanc').checked = false;

    // Clear active filters
    activeFilters = [];
    includeXLancFilter = false;

    // Fetch and display all job listings without any filters
    fetchAllJobListings(1);

    console.log("Filters cleared and job listings reset."); // Debugging logs
});

// Function to apply search functionality independently of filters
function applySearch(searchTerm = '') {
    let searchData = jobData;

    // Filter by search term
    if (searchTerm) {
        searchData = searchData.filter(job => 
            job.title?.toLowerCase().includes(searchTerm.toLowerCase()) || 
            job.company?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    resetJobListings(); // Clear current listings
    populateJobListings(searchData.slice(0, jobsPerPage)); // Load the search results for the first page
    generatePagination(); // Generate pagination for search results
    scrollToRecentListings();
}

// Reset the job listings
function resetJobListings() {
    const jobListingsContainer = document.getElementById("job-listings");
    if (jobListingsContainer) jobListingsContainer.innerHTML = '';
}

// Show the loading indicator
function showLoadingIndicator() {
    const preloader = document.getElementById("job-preloader");
    if (preloader) preloader.style.display = "flex";
}

// Hide the loading indicator
function hideLoadingIndicator() {
    const preloader = document.getElementById("job-preloader");
    if (preloader) preloader.style.display = "none";
}

// Event listeners for filters and search
document.getElementById('apply-filters-button')?.addEventListener('click', () => {
    applyFilters(
        Array.from(document.querySelectorAll('.job-type-filter:checked')).map(el => el.value),
        document.getElementById('include-xlanc').checked
    );
});

document.getElementById('search-form')?.addEventListener('submit', (e) => {
    e.preventDefault();
    applySearch(document.getElementById('search-input').value);
});

// Initial fetch on page load
window.addEventListener('load', () => {
    fetchAllJobListings(); // Fetch paginated jobs initially
});

// Scroll to recent listings section
function scrollToRecentListings() {
    const recentListings = document.getElementById("recent-listings");
    if (recentListings) recentListings.scrollIntoView({ behavior: "smooth" });
}

// Update HTML to move the filter container and style the job cards
document.addEventListener('DOMContentLoaded', () => {
    // Move the filter container
    const filterContainer = document.querySelector('.filter-container');
    const jobListingsContainer = document.getElementById('job-listings');
    if (filterContainer && jobListingsContainer) {
        jobListingsContainer.parentNode.insertBefore(filterContainer, jobListingsContainer);
    }

    // Style the job cards
    const jobListings = document.querySelectorAll('.job-listing');
    jobListings.forEach(jobListing => {
        jobListing.style.padding = '20px';
        jobListing.style.borderRadius = '10px';
        jobListing.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
        jobListing.style.marginBottom = '20px';
    });
});